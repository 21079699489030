import { isChromeBrowser, matchesAppUrl } from '@helpers/appBanner';
import { Button, ButtonTheme, Typography, TypographyType } from '@vivino/js-react-common-ui';
import { generateQueryUrl, useI18N } from '@vivino/js-web-common';
import React from 'react';

import styles from './appBanner.module.scss';

const TRANSLATIONS = {
  buyTheRightWine: 'common.buy_the_right_wine',
  openApp: 'common.open_app',
};
const AppBanner = () => {
  const { t } = useI18N();
  const userAgent = globalThis.navigator?.userAgent;
  const isAndroid = userAgent?.match(/Android/i);
  const urlMatch = matchesAppUrl({ path: globalThis.location.pathname });

  const appStoreButtonUrl = generateQueryUrl({
    baseUrl: `https://app.vivino.com${globalThis.location?.pathname}${globalThis.location?.search}`,
    params: [
      {
        name: 'utm_source',
        value: 'web_banner_small',
      },
      {
        name: 'app_store_url',
        value: globalThis.__GOOGLE_PLAY_URL__,
      },
    ],
  });

  if (!isAndroid || !isChromeBrowser() || !urlMatch) {
    return null;
  }

  return (
    <div className={styles.container}>
      <a href={appStoreButtonUrl} data-testid="appLink">
        <div className={styles.textContainer}>
          <div className={styles.appImage}></div>
          <div className={styles.title}>
            <Typography type={TypographyType.LabelMedium}>
              Vivino: {t(TRANSLATIONS.buyTheRightWine)}
            </Typography>
            <div className={styles.rating}>
              <div className={styles.stars}></div>
              <Typography type={TypographyType.Caption}>188k</Typography>
            </div>
          </div>
          <div>
            <Button theme={ButtonTheme.Primary}>{t(TRANSLATIONS.openApp)}</Button>
          </div>
        </div>
      </a>
    </div>
  );
};

export default AppBanner;
